class ZIDXAccountErrorLogs implements ZIDXPage {
    constructor() {
    }

    getPageClass() {
        return "zidxAccountErrorLogsContainer";
    }

    getMatchURL() {
        return "/account/error-logs";
    }

    render() {
    }
}